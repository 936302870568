import React from 'react'

// import layout
import WorkLayout from '../../layout/work-layout'

// components
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../../utils/seo'

const Work = () => {
  // DEFINE THERE ALL THE WORK CONSTs
  const title = 'Museo de Medici',
    desc =
      'Servizio fotografico per il Museo de Medici, nel palazzo di Sforza Almeni a Firenze.',
    subtitle = 'Bottega del Foggini',
    roles = ['Videomaker','Fotografo'],
    videoSrc = ['https://www.youtube.com/watch?v=QVXc6oZscPU'],
    // put this TRUE only if there are logos to show
    logo = true

  return (
    <WorkLayout
      title={title}
      subtitle={subtitle}
      roles={roles}
      videoSrc={videoSrc}
      logo={logo}
    >
      <Seo title={title}  description={desc} />
      {/* COVER IMG */}
      <StaticImage
        src='../../images/cover-museo-medici.jpg'
        alt='Cover per il servizio fotografico al Museo de Medici'
        className='work-cover shadow-black'
      />
      {/* DESC */}
      <p className='work-description desc-1 margin-bottom'>
        <span>
          <em>Nell’antico palazzo al numero 12 di via
          dei Servi</em>, che fu la residenza del cavaliere Sforza Almeni,
          consiglieri di Cosimo I de’ Medici, ha sede il primo ed unico spazio
          museale interamente dedicato alla storia della Grande Dinastia.
        </span>
      </p>
      {/* DESC n.2 */}
      <p className='work-description desc-2 margin-bottom'>
        <span>
          In occasione dei 300 anni dalla morte di Marguerite Louise d’Orlèans
          la Vicesindaca <em>Alessia Bettini</em> ha scoperto il busto marmoreo della
          bottega del Foggini ritraente la granduchessa. L’opera, della
          <em> Fondazione Anna Maria Luisa de’ Medici</em> conservata al <em>Museo de’ Medici</em>,
          è un unicum nel panorama italiano.
        </span>
      </p>
      {/* DESC n.3 */}
      <p className='work-description desc-3'>
        <span>
          Nelle sale la storia della famiglia è raccontata attraverso
          l’esposizione di opere d’arte, documenti e cimeli originali, testimoni
          diretti del dominio plurisecolare dei Medici a Firenze.
        </span>
        <span>
          <em>Il museo</em> ospita molti capolavori inediti tra i quali ritratti medicei
          oltre a pitture, disegni, incisioni, manoscritti, libri a stampa,
          medaglie e moltissime lettere medicee autografe.
        </span>
        <span>
          Tra le curiosità anche un grande diorama che illustra la famosa
          battaglia di Anghiari e la ricostruzione olografica delle corone
          granducali.
        </span>
        <span>
          Completano il percorso un bookshop specializzato ed una vera e propria
          cantinetta medicea dove è possibile conoscere ed acquistare i vini
          toscani tutelati dai Medici fin dal 1716.
        </span>
      </p>
      {/* ADD HERE ALL THE PHOTOS */}
      <StaticImage
        src='../../images/photo/museo-medici-photo-2.jpg'
        alt='Foto Museo Medici 1'
        className='work-img work-img-containe video-1 margin-bottom margin-left-auto'
        imgClassName='img shadow-black'
        quality={100}
      />
      <StaticImage
        src='../../images/photo/museo-medici-photo-1.jpg'
        alt='Foto Museo Medici 2'
        className='work-img work-img-smaller video-2 shadow-black'
        quality={100}
      />
      {/* ADD HERE ALL THE LOGOS */}
      <div className='logo-container'>
      <StaticImage
        src='../../images/logo/logo-firenze.png'
        alt='Logo Comune di Firenze'
        className='work-logo-mid logo-1'
      />
      <StaticImage
        src='../../images/logo/logo-medici.png'
        alt='Logo Museo Medici'
        className='work-logo-big logo-3'
      />
      <StaticImage
        src='../../images/logo/logo-anna-maria.png'
        alt='Logo Fondazione Anna Maria'
        className='work-logo-bigger logo-2'
      />
      </div>
    </WorkLayout>
  )
}

export default Work
